.item{
    font-size: 0.9rem;
    margin: auto 1.82rem auto 1.82rem;
}

.navbar{
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
}

.link{
    text-decoration: none;
    color: inherit;
}

.title{
    text-decoration: none;
    color: inherit;
    font-family: 'DM Serif Display', serif;
}

.title:hover{
    color: inherit;
}

.link:hover,
.link:active,
.active{
    padding-bottom: 0.15rem;
    color: #005b8f;
    border-bottom: 2px solid #005b8f;
}
