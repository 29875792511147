@media (min-width: 992px) {
    .modal{
        width: 50%;
        max-width: 50%;
    }
}

.attribs{
    color: rgb(83, 83, 83);
}

.link,
.link_nodec,
.link_nodec:hover{
    text-decoration: none;
    color: inherit;
}

.textarea{
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
    height: auto;
    max-height: 20rem;
    width: 100%;
}

.image{
    max-width: 720px;
    margin: auto;
    display: block;
}