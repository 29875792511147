.flag{
    width: 3.75rem;
    height: 1.75rem;
    float: right;
    /* margin-right: 1rem; */
}

.link{
    text-decoration: none;
    color: inherit;
}

.link_nodec,
.link_nodec:hover{
    text-decoration: none;
    color: inherit;
}