.container{
    margin-top: 1.5rem;
    margin-bottom: 2rem;
}

.carousel{
    padding-top: 1rem;
}

.carousel:hover{
    cursor: pointer;
}