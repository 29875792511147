.container{
    margin-top: 1.5rem;
    height: 100%;
}

.wrapper{
    border: 2px solid black;
    border-radius: 5px;
    height: 30rem;

    padding-top: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.pdf{
    height: 20rem;
    width: inherit;
}

.not_found__h2{
    margin: 2rem auto 2rem auto;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    text-align: center;
    color: rgb(95, 95, 95);
    border: 0.25rem solid #ccc;
    border-radius: 25px;
    width: 94%;
}

.not_found__container{
    height: inherit;
}