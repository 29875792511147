.container{
    margin-top: 1.5rem;
}

.descrpition{
    padding-top: 2rem;
    line-height: 2.5rem;
    text-align: justify;
    text-justify: inter-word;
}

.h3{
    padding-top: 1.0rem;
}